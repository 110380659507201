<script>
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import {UBtnDropdown, UList, CloseOverlay} from 'uloc-vue'
import ListaLotesMkt from "components/marketing/gerador/List";
import {gerarConteudo} from "@/domain/marketing/services";

export default {
  name: 'GeradorConteudoMkt',
  inject: {
    erplayout: {
      default() {
        console.error('Main needs to be child of ErpLayout')
      }
    }
  },
  directives: {CloseOverlay},
  components: {
    ListaLotesMkt,
    ECol,
    ERow,
    UBtnDropdown,
    UList
    // Layout,
  },
  data() {
    return {
      leilao: {
        id: null
      },
      gerando: false,
      resultado: {
        tipo: null,
        url: null,
        conteudo: null
      }
    }
  },
  computed: {},
  mounted() {
    this.erplayout.erpheader.menu.setActiveMenu('marketing')
  },
  destroyed() {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    gerar ({tipo, lotes}) {
      this.gerando = true
      gerarConteudo({
        tipo: tipo,
        lotes: lotes.map(l => l.id)
      }).then(({data}) => {
        this.gerando = false
        if (tipo === 'html') {
          this.resultado.url = data.url
          this.resultado.conteudo = data.html
        } else {
          this.resultado = data
        }
        this.resultado.tipo = tipo
        console.log(data)
      }).catch(error => {
        this.alertApiError(error)
        this.gerando = false
      })
    }
  },
  meta: {
    title: 'Marketing',
    name: 'Marketing'
  }
}
</script>

<template>
  <transition name="slide" appear>
    <div class="transition-generic w-full wrapper-lg">
      <e-row>
        <e-col><h2 class="title">Gerador de Conteúdo para Marketing</h2></e-col>
        <e-col class="text-right">
          <div v-if="false" class="u-btn-group-container">
            <u-btn label="Gerar Conteúdo" no-caps color="dark"/>
            <!--              <u-btn icon="caret-down" icon-type="fa" no-caps color="dark" class="btn-right" />-->
            <u-btn-dropdown
                color="dark"
                :popover-offset="[0, 4]"
            >
              <u-list class="list-default flex column" link>
                <u-btn class="u-btn-list" v-close-overlay align="left" label="Por Leilão"
                       icon-type="fa" icon-style="light" flat no-caps/>
                <u-btn class="u-btn-list" align="left" label="Por Bem" icon-type="fa" icon-style="light"
                       flat no-caps/>
              </u-list>
            </u-btn-dropdown>
          </div>

        </e-col>
      </e-row>

      <lista-lotes-mkt ref="lotes" class="m-t m-b" :leilao="leilao" @gerar="gerar" :gerando="gerando" /> <!-- @loaded="(lotes) => leilao.lotes = lotes" -->

      <div v-if="resultado.tipo === 'html'">
        <p>Prévisualização</p>
        <iframe style="width: 100%; height: 800px; overflow: auto" :src="resultado.url"></iframe>
        <div class="m-t">
          <p>Conteúdo HTML</p>
          <textarea class="sl-textarea" style="width: 100%; height: 400px" v-model="resultado.conteudo"></textarea>
        </div>
      </div>
      <div v-else-if="resultado.url">
        <p>Prévisualização</p>
        <iframe style="width: 100%; height: 800px; overflow: auto" :src="resultado.url"></iframe>
      </div>
    </div>
  </transition>
</template>

